@use "../../../scss/variables" as vars;

.modal {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  //   border: 1px solid red;
  background: rgba(0, 0, 0, 0.85);
}
.bodyModal {
  //   border: 1px solid blue;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  //   height: 90vh;
  //   width: 90vw;
}
.body {
  //   height: 90vh;
  //   width: 90vw;
  //   border: 1px solid blue;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  overflow: scroll;
}

.closeButton {
  transition: transform 0.35s;
  background-color: vars.$color-dark_grey_50;
  height: 30px;
  width: 40px;
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 20px;
  z-index: 2;
  &:hover {
    transform: scale(1.2);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  background-color: vars.$color-light_grey_10;
  overflow: hidden;
  min-height: 70vh;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  @media screen and (max-width: 800px) {
    min-height: 70vh;
  }
}

.imgContainer {
  height: 100%;
  //   border: 1px solid red;
  img {
    height: 450px;
    transform-origin: 15% 50%;
    transform: scale(2.3);
  }
  @media screen and (max-width: 800px) {
    height: 400px;
    //   border: 1px solid red;
    //   overflow: hidden;
    width: 100%;
    img {
      height: 80%;
      transform-origin: 45% 35%;
      transform: scale(2.2);
    }
  }
}

.prod {
  .prodImg {
    min-height: 320px;
    max-width: 100%;
    // border: 1px solid red;
    overflow: hidden;
    img {
      // height: 100%;
      transform: scale(1.7);
      transform-origin: 50% 30%;
      max-width: 100%;
      @media screen and (max-width: 700px) {
        transform: scale(1.3);
        transform-origin: 50% 35%;
      }
    }
  }
  .prodBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;

    background-color: black;
    border-radius: 20px;
    height: 45px;
  }
}

@use '../../scss/variables.scss' as vars;

.submitBtn{
 background-color: vars.$color-dlcGreen;   
 color: black;
 border-radius: 20px;
 height: 30px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 2rem;
}
@use "../../../scss/variables.scss" as vars;

.styledBtn {
  height: 55px;
  border-radius: 70px;
  border: 3.5px solid vars.$color-dark_grey_50;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 25px;
  font-size: 1.4rem !important;
}

.styledBtnOpen {
//   border: rgb(255, 255, 255) 3px solid;
}

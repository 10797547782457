@use "../../scss/variables.scss" as vars;

input {
  display: flex;
  flex-direction: row;
  border: 0px solid;
  border-radius: 70px;
  height: 50px;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 1.4rem;
    @media screen and (max-width: 800px) {
      font-size: 1.2rem;
    }
  }
}

// textarea {
//   display: flex;
//   flex-direction: row;
//   border: 0px solid;
//   border-radius: 70px;
//   height: 50px;
//   color: black;
//   &:focus {
//     outline: none;
//   }
//   &::placeholder {
//     font-size: 2rem;
//     @media screen and (max-width: 800px) {
//       font-size: 1.2rem;
//     }
//   }
// }

.inputDiv {
  border-radius: 70px;
  height: 55px;
  border: 3.5px solid vars.$color-dark_grey_50;
  width: 100%;
  margin-inline-end: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    margin-inline-end: 1.5vh;
  }
  input[type="text"] {
    background-color: white;
    color: black;
    border: 0px;
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
      @media screen and (max-width: 800px) {
        font-size: 1rem;
      }
    }
  }
  input[type="email"] {
    background-color: white;
    color: black;
    border: 0px;
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
      @media screen and (max-width: 800px) {
        font-size: 1rem;
      }
    }
  }
}

.inputDivHalf {
  border-radius: 70px;
  height: 55px;
  border: 3.5px solid vars.$color-dark_grey_50;
  width: 100%;
  margin-inline-end: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    margin-inline-end: 1.5vh;
  }
  input[type="text"] {
    background-color: white;
    color: black;
    border: 0px;
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
      @media screen and (max-width: 800px) {
        font-size: 1rem;
      }
    }
  }
  input[type="email"] {
    background-color: white;
    color: black;
    border: 0px;
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
      @media screen and (max-width: 800px) {
        font-size: 1rem;
      }
    }
  }
}

.textareaDiv {
  //   border: 3.5px solid vars.$color-dark_grey_50;
  width: 100%;
  margin-inline-end: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 800px) {
    margin-inline-end: 1vh;
  }

  textarea {
    border: 3.5px solid vars.$color-dark_grey_50;
    border-radius: 28px;
    height: 200px;
    color: black;
    // background-color: transparent;
    resize: none;
    &:focus {
      color: black;
      outline: none;
    }
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
      @media screen and (max-width: 800px) {
        font-size: 1rem;
      }
    }
  }
}

.sendButton {
  height: 55px;
  border-radius: 30px;

  border: 0px;
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  &:hover {
    background-color: vars.$color-dark_grey_50;
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // border:1px solid green;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  // border:1px solid red;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 10px;
  top: 2px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

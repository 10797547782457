@use "../../../scss/variables.scss" as vars;

.contentDiv {
  //   border: 1px solid red;
  min-width: 100%;
  background-color: white;
  border-radius: 30px;
  position: absolute;
  //   max-height: 150px;
  overflow-y: scroll;
  box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 25px;
  scrollbar-width: none;
  border: 3.5px solid vars.$color-dark_grey_50;
  -ms-overflow-style: none;
  opacity: 0;
  transform: translateY(-5%);
  transition: transform 300ms ease-in-out, opacity 100ms ease-in-out;
  pointer-events: none;
  height: 0px;
}

.contentDiv::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.contentDivOpen {
  margin-top: 5px;
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms ease-in-out, opacity 100ms ease-in-out;
  pointer-events: all;
  z-index: 10;
  height: 150px;
}

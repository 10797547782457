@use "../../scss/variables" as vars;

.productsDiv {
  // border: 1px solid red;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    max-height: 500px;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.detailsBtn {
  color: white;
  background-color: vars.$color-dark_grey_50;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    opacity: 30%;
  }
}

@use "../../../scss/variables" as vars;

.item {
  border-bottom: 1.5px solid vars.$color-light_grey_20;
  color: black;
  font-size: 1.4rem;
  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
}
.item:hover {
  background-color: vars.$color-light_grey_20;
  color: white;
  //   border-bottom: 1px solid black ;
}

@use "../../scss/variables.scss" as vars;

.headerTitle {
  color: vars.$color-dark_grey_50;
  font-size: 1.9rem;

  @media screen and (max-width: 700px) {
    font-size: 2rem;
    font-weight: bold;
  }

  &:hover {
    text-decoration: underline;
  }
  &.selected {
    color: vars.$color-black !important;
  }
  &.selectedDark {
    color: vars.$color-white !important;
  }
}

.logo {
  height: 8vh;
  width: 17%;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline-start: 5.2rem;
  @media screen and (max-width: 700px) {
    height: 12vh;
    width: 55%;
    margin-top: 0rem;
  }
}

.languageFlag {
  // border: 1px solid red;
  height: 40px;
  width: 80px;
  display: flex;
  flex-direction: row;

  justify-content: center;
  @media screen and (max-width: 700px) {
    height: 50px;
    width: 90px;
  }
  img {
    border-radius: 10px;
    height: 35px;
    width: 60px;
    @media screen and (max-width: 700px) {
      height: 50px;
      width: 90px;
    }
  }
  .chooseLanguageDiv {
    // border: 1px solid red;
    // height: 80px;
    color: white;
    // background-color: black;
    border-radius: 10px;

    @media screen and (max-width: 700px) {
      width: 90px;
    }
    img {
      border-radius: 10px;
      height: 30px;
      width: 50px;
      @media screen and (max-width: 700px) {
        height: 40px;
        width: 80px;
      }
    }
  }
}

.flagsContainer{
  height: 30px;
  border-radius: 20px;
  .flag{
    height: 25px;
    border-radius: 10px;
    // border:1px solid red; 
    img{
      border-radius: 10px;
      height: 25px;
      width: 75%;
    }
  }
}
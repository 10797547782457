@use "../../../scss/variables" as vars;

.modal {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  //   border: 1px solid red;
  background: rgba(0, 0, 0, 0.85);
}
.bodyModal {
  //   border: 1px solid blue;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  //   height: 90vh;
  //   width: 90vw;
}
.body {
  //   height: 90vh;
  //   width: 90vw;
  //   border: 1px solid blue;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  overflow: scroll;
}

.closeButton {
  transition: transform 0.35s;
  background-color: vars.$color-dark_grey_50;
  height: 30px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  border-radius: 20px;
  z-index: 2;
  &:hover {
    transform: scale(1.2);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  background-color: vars.$color-light_grey_10;
  overflow: hidden;
  height: 70vh;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  @media screen and (max-width: 800px) {
    min-height: 80vh;
  }
}

.contentHorizontal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  background-color: vars.$color-light_grey_10;
  overflow: hidden;
  height: 30vh;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.imgContainer1 {
  //   border: 1px solid red;
  height: 70vh;
  width: 100%;
  overflow: hidden;
  img {
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
    // width: 100%;
    // border: 1px solid red;
    // transform-origin: 50% 25%;
    // transform: scale(1.5);
  }
  @media screen and (max-width: 800px) {
    height: 40vh;
    //   border: 1px solid red;
    //   overflow: hidden;
    // width: 100%;
    // object-fit: contain;
    img {
      height: 100%;
      // width: 100%;
      border-radius: 20px;
      object-fit: cover;
      object-position: center;
      // transform-origin: 50% 50%;
      // transform: scale(1.3);
    }
  }
}

.greenBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #afd774;
  height: 40px;
  border-radius: 20px;
}

@use "../../scss/variables.scss" as vars;

.productsDiv {
  // border: 1px solid blue;
  transition: transform 0.75s;
  min-height: 400px;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    min-height: 450px;
  }
  @media screen and (max-width: 1200px) {
    min-height: 400px;
  }
  @media screen and (min-width: 1950px) {
    min-height: 500px;
  }
  .titleContainer {
    padding-left: 2.5rem;
    padding-top: 1.75rem;

    @media screen and (max-width: 800px) {
      padding-left: 2.5rem;
      padding-top: 2rem;
    }
    .cardTitle {
      font-family: vars.$font-esquare;
      font-size: 4.5rem;
      @media screen and (max-width: 1200px) {
        font-size: 3.3rem;
      }
    }
  }
  .buttonContainer {
    min-height: 35px;

    margin-top: 10px;
    @media screen and (max-width: 800px) {
      height: 30px;
    }
    .cardButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2rem;
      border-radius: 30px;

      padding-inline: 2.2vh;
      border: 1px solid white;

      @media screen and (max-width: 1200px) {
        padding-inline: 1.8vh;
        font-size: 1.2rem;
      }
      @media screen and (max-width: 800px) {
        padding-inline: 1.5vh;
        font-size: 1.2rem;
      }
    }
  }
  .subDiv {
    // max-height: 42vh;
    // border: 1px solid white;
    // transform: translateY(3rem);
    // border: 1px solid blue;

    .imageContainer {
      height: 250px;
      // border: 1px solid green;
      pointer-events: none;

      overflow: hidden;

      @media screen and (max-width: 800px) {
        // border: 1px solid green;
        min-height: 150px;
      }

      .menu {
        // padding-top: 25px;/
        width: 100%;
        height: 100%;
        object-position: bottom;
        // border: 1px solid red;
        object-fit: scale-down;

        // transform: scale(1.2) !important;
        @media screen and (min-width: 2000px) {
          padding-top: 0px;
        }

        @media screen and (max-width: 800px) {
          width: 100%;
          height: 100%;
          object-position: bottom;
          border: 1px solid red;
          object-fit: scale-down;
        }
      }
      .review {
        // border: 1px solid red;
        width: 100%;
        height: 100%;
        object-fit: cover;
        height: 250px;
        // object-position: bottom;

        // transform: scale(1.4) !important;
        @media screen and (min-width: 2000px) {
          // transform: scale(1.3) !important;
          // padding-top: 0px;
          // height: 100px;
          object-fit: scale-down;
        }
      }
    }

    .arrowDiv {
      border-radius: 50%;
      background-color: white;
      height: 50px;
      width: 50px;
      @media screen and (max-width: 800px) {
        height: 30px;
        width: 30px;
      }
      .arrow {
        height: 40px;
        @media screen and (max-width: 800px) {
          height: 30px;
        }
      }
    }
    @media screen and (max-width: 800px) {
      max-height: 225px;
      span {
        font-size: 1.5rem !important;
        color: white;
      }
      .arrowDiv {
        border-radius: 50%;
        background-color: white !important;
        height: 30px !important;
        width: 30px !important;
        .arrow {
          height: 30px !important;
        }
      }
    }
  }
  &:hover {
    transform: scale(1.05);
  }
}
.marquee {
  // border: 1px solid red;
  height: 20rem;
  // border: 1px solid red;
  @media screen and (max-width: 800px) {
    height: 8rem;
  }
  span {
    font-size: 12rem !important;
    @media screen and (max-width: 800px) {
      font-size: 5rem !important;
    }
  }
}

.space {
  height: 20vh;
  @media screen and (max-width: 800px) {
    height: 90px;
  }
}

.bigTitle {
  font-size: 11rem;

  @media screen and (max-width: 800px) {
    font-size: 5rem;

    padding-left: 3.5rem;
  }
}

.fistDiv {
  position: relative;
  height: 100vh;
  width: 100vw;

  video {
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    background: #000;
  }
}

.contactsButton {
  border: 3px solid black;
  height: 60px;
  border-radius: 30px;
  &:hover {
    border: 1px solid black;
  }
}

.description {
  margin-bottom: 10vh;
  .tendina {
    padding: 3rem;
    border-top: 1px solid black;
    border-top-left-radius: 20px;
    border-left: 1px solid black;
  }
  .text {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-bottom-right-radius: 20px;
  }
}

.fistContainer {
  position: absolute;
  // border: 1px solid red;
  height: 20vh;
  bottom: 0;
  @media screen and (max-width: 800px) {
    margin-top: 2rem;
  }
  .textDiv {
    // padding-left: 5rem;

    @media screen and (max-width: 800px) {
      transform: translateY(-15rem);
      // padding-left: 3rem;
    }
  }
}

.buttonDiscover {
  margin-top: 7vh;
  background-color: black !important;
  border: 1px solid white;
  @media screen and (min-width: 2000px) {
    margin-top: 15vh !important;
  }
  @media screen and (min-height: 1300px) {
    margin-top: 15vh !important;
  }
}

.securityDiv {
  border-radius: 20px;
  min-height: 230px;
  border: 2px solid black;
  padding-inline: 3.5rem;
  .iconcina {
    height: 100px;
    width: 100px;
    // border: 1px solid red;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .text {
    width: 100%;
    // border: 1px solid blue;
  }
}

.swiperContainer {
  overflow-x: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.innovationSlide {
  height: 700px;
  cursor: pointer;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }

  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .innovationBackgroundImage {
    height: 100%;
    width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: center;
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.environmentSlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .environmentBackgroundImage {
    height: 100%;
    width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: center;
      @media screen and (max-width: 800px) {
        // transform-origin: 50% 50%;
        // transform: scale(1.62);
      }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.privacySlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .privacyBackgroundImage {
    height: 100%;
    width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: center;
      @media screen and (max-width: 800px) {
        // transform-origin: 35% 50%;
      }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.customizationSlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .customizationBackgroundImage {
    height: 100%;
    width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: center;
      @media screen and (max-width: 800px) {
        // transform-origin: 35% 50%;
        // transform: scale(1.6);
      }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.assistanceSlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .assistanceBackgroundImage {
    height: 100%;
    width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;

      object-position: center;
      // transform-origin: 35% 45%;
      // transform: scale(1.4);
      @media screen and (max-width: 800px) {
        // transform-origin: 35% 50%;
        // transform: scale(1.4);
      }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.buttonRow {
  z-index: 2;

  bottom: 0;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  height: 60px;
  .button {
    transition: transform 0.35s;
    background-color: vars.$color-dark_grey_50;
    height: 30px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 20px;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.productContainerMob {
  min-height: 50vh;
  // border: 1px solid red;
  border-radius: 20px;
  background-color: black;
  transition: transform 0.75s;
  .productTondino {
    height: 20px;
    border-radius: 20px;
    border: 1px solid white;
  }
  .imageContainer {
    height: 250px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .reviewImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .arrowDiv {
    border-radius: 50%;
    background-color: white;
    height: 40px;
    width: 40px;

    .arrow {
      height: 40px;
    }
  }
  &:hover {
    transform: scale(1.05);
  }
}

@use "../../scss/variables.scss" as vars;

.pageDiv {
  padding-left: 10rem;
  padding-right: 10rem;

  @media screen and (max-width: 700px) {
    padding: 3rem;
  }
}

.productsBtn {
  background-color: #e3fc02;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 20px;
  font-size: 20px;
  font-family: vars.$font-helvetica;
  font-weight: bold;
  @media screen and (max-width: 700px) {
    font-size: 13px;
  }
}

.greyDiv {
  background-color: black;
  color: white;
  padding: 3rem;
  border-radius: 20px;
  min-height: 450px;
  @media screen and (max-width: 700px) {
    min-height: 300px;
    padding: 3rem;
  }
  @media screen and (max-width: 1200px) {
    min-height: 500px;
    padding: 3rem;
  }
}
.revContainer {
  margin-top: 130px;
  .revDiv {
    border: 1px solid black;
    border-radius: 20px;
    min-height: 350px;

    .colorLine {
      height: 20px;
      border: 1px solid blue;
      background-color: blue;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      .imageRound {
        height: 100px;
        width: 100px;
        border-radius: 70px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .revComment {
      margin-top: 70px;
      min-height: 150px;
      border-bottom: 1px solid black;
      padding-inline: 40px;
    }
    .revOwner {
      min-height: 90px;
      // border: 1px solid red;
    }
  }
}

.pallino {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: vars.$color-dark_grey_40;
}
.pallinoSelected {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: vars.$color-dark_grey_90;
}

.valueSlide {
  height: 700px;
  cursor: pointer;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .BackgroundImage {
    height: 100%;
    // width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;

      width: 100%;
      // transform-origin: 20% 45%;
      // transform: scale(3.5);
      // border: 1px solid red;
    }
    @media screen and (max-width: 800px) {
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 100%;
      }
    }
  }
  .title {
    z-index: 2;
    top: 100px;
    left: 20px;
    position: absolute;
    @media screen and (max-width: 800px) {
      top: 65px;
      line-height: 1.05;
    }
  }
  .greyWeAre {
    z-index: 2;
    top: 40px;
    left: 20px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.buttonRow {
  z-index: 2;

  bottom: 0;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  height: 60px;
  .button {
    transition: transform 0.35s;
    background-color: vars.$color-dark_grey_50;
    height: 30px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 20px;
    &:hover {
      transform: scale(1.2);
    }
  }
}

@use "../../scss/variables.scss" as vars;

.contactsButtonDark {
  border: 1px solid black;
  height: 60px;
  border-radius: 30px;
  @media screen and (max-width: 800px) {
    height: 40px;
  }
  &:hover {
    border: 1px solid black;
  }
  .arrow {
    height: 50px;
    @media screen and (max-width: 800px) {
      height: 30px;
    }
  }
}
.contactsButtonWhite {
  border: 1px solid white;
  height: 60px;
  border-radius: 30px;
  @media screen and (max-width: 800px) {
    height: 30px;
  }
  &:hover {
    border: 1px solid white;
  }
  .arrow {
    height: 50px;
    @media screen and (max-width: 800px) {
      height: 30px;
    }
  }
}

.title {
  font-size: 3.5rem;
  font-family: vars.$font-helvetica;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 1200px) {
    font-size: 2.5rem;
  }
  
}

.logo {
  height: 40px;
  @media screen and (max-width: 800px) {
    height: 25px;
  }
}

.firstDiv {
  min-height: 90vh;
  @media screen and (max-width: 800px) {
    min-height: 50vh;
  }
  .text {
    @media screen and (max-width: 800px) {
      font-size: 3rem !important;
    }
  }
}

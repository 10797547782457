a,
a:link,
a:visited {
  color: white ;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@use "../../scss/variables.scss" as vars;
.videodiv {
  height: 55vh;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-image: url("../assets/mano2.png");
  background-size: cover;
  background-color: black;
  position: relative;
  transition: transform 0.75s;
  @media screen and (max-width: 700px) {
    height: 40vh;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-image: url("../assets/mano2.png");
    background-size: cover;
    background-color: black;
    position: relative;
    transition: transform 0.75s;
    video {
      border-radius: 30px;
    }
  }
  &:hover {
    transform: scale(1.03);
  }
}

.playBtn {
  position: absolute;
  height: 13vh;
  width: 13vh;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0.6;

  .icon {
    color: black;
  }
}

.imagediv {
  height: 40vh;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  position: relative;
  background-image: url("https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/ales-nesetril-Im7lZjxeLhg-unsplash.jpg");

  transition: transform 0.75s;

  &:hover {
    transform: scale(1.03);
  }
  @media screen and (max-width: 700px) {
    height: 20vh;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
    background-image: url("https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/ales-nesetril-Im7lZjxeLhg-unsplash.jpg");

    transition: transform 0.75s;
  }

  .words {
    height: 40vh;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.requestdiv {
  min-height: 80rem;
  border-radius: 30px;
  background-color: black;
  transform: translateY(-12vh);
  @media screen and (max-width: 700px) {
    height: 100%;
    border-radius: 30px;
    background-color: black;
    transform: translateY(4vh);
  }
}

.inputDiv {
  border-radius: 70px;
  height: 50px;
  border: 3.5px solid vars.$color-dark_grey_50;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  input[type="text"] {
    background-color: black;
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
    }
  }
  input[type="email"] {
    background-color: black;
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
    }
  }
}

.choicediv {
  height: 40px;
  border-radius: 20px;
  // width: 22.5%;
  border: 3.5px solid vars.$color-dark_grey_50;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: vars.$font-esquare;
  color: vars.$color-dark_grey_50;
  padding: 1rem;
  @media screen and (max-width: 700px) {
    height: 40px;
    border-radius: 20px;
    width: 100%;
    margin-top: 10px;
    border: 3.5px solid vars.$color-dark_grey_50;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: vars.$font-esquare;
    color: vars.$color-dark_grey_50;
  }
}

.choosenDiv {
  height: 40px;
  border-radius: 20px;
  // width: 22.5%;
  border: 3.5px solid vars.$color-dark_grey_50;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: vars.$font-esquare;
  color: vars.$color-dark_grey_50;
  background-color: white;
  padding: 1rem;
  @media screen and (max-width: 700px) {
    height: 40px;
    border-radius: 20px;
    width: 100%;
    margin-top: 10px;
    border: 3.5px solid vars.$color-dark_grey_50;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: vars.$font-esquare;
    color: vars.$color-dark_grey_50;
    background-color: white;
  }
}

textarea {
  border: 3.5px solid vars.$color-dark_grey_50;
  border-radius: 45px;
  height: 150px;
  color: white;
  background-color: transparent;
  resize: none;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: vars.$color-dark_grey_50;
    font-size: 1.4rem;
    @media screen and (max-width: 800px) {
      font-size: 1rem;
    }
  }
}

.faqdiv {
  height: 40vh;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  position: relative;
  background-color: black;

  transition: transform 0.75s;

  &:hover {
    transform: scale(1.03);
  }
  @media screen and (max-width: 700px) {
    height: 20vh;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;

    transition: transform 0.75s;
  }

  .words {
    height: 40vh;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.freccetta {
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  background-color: white;
  img {
    height: 30px;
    width: 30px;
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // border:1px solid green;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  // border:1px solid red;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 10px;
  top: 2px;
  width: 10px;
  height: 20px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

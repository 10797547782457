@use "../../scss/variables.scss" as vars;

.pagediv {
  padding: 4.5rem;

  @media screen and (max-width: 700px) {
    padding: 3rem;
  }
}

.faqRow {
  background-color: black;
  color: white;
  padding: 15px;
  border-radius: 30px;
  min-height: 60px;
}
.faqRow_Last {
  background-color: vars.$color-dark_grey_50;
  color: white;
  padding: 15px;
  border-radius: 30px;
  min-height: 60px;
}

.responseDiv {
  background-color: black;
}

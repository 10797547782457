@use "../../scss/variables.scss" as vars;
.text {
  a {
    text-decoration: none;
    color: white;
  }
}

.rightDiv {
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 50%;

  &.fixed {
    top: 16vh;
    position: fixed;
    width: 39%;
    z-index: 0;
    animation: show_div 0.35s;
  }
  &.hidden {
    top: 16vh;
    position: fixed;
    width: 39%;
    z-index: 0;
    animation: hide_div 0.35s;
    opacity: 0;
  }
}

.imageContainer {
  top: 10vh;
  // border: 1px solid red;
  width: 40%;

  height: 50vh !important;
  // border: 1px solid red !important;
  position: sticky;
  position: -webkit-sticky !important;
  // top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  // overflow: hidden;
  @media screen and (max-width: 1500px) {
    // transform-origin: 50% 80%;
    top: 5vh;
  }
  .image {
    // background-color: white;
    // border-radius: 30px;

    // border: 1px solid red;
    // overflow: hidden;
    // border: 1px solid red;

    .imageDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      height: 450px;
      width: 100%;
      // border: 1px solid blue;

      @media screen and (max-width: 1200px) {
        // transform-origin: 50% 80%;
        height: 350px;
        width: 100%;
      }
      img {
        // position: sticky;

        min-height: 350px;

        object-position: center;
        // border: 1px solid red;
        object-fit: cover;
        // height: 100%;
        width: 100%;
        // transform: scale(1.2);
        // transform-origin: 50% 50%;
      }
    }
  }
}

.imageContainerMob {
  // border: 1px solid red;
  width: 100%;

  height: 75vh !important;
  // border: 1px solid red !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // top: 0px;

  // overflow: hidden;

  .imageMob {
    // background-color: white;
    // border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-position: center;
    // border: 1px solid red;
    // overflow: hidden;
    // border: 1px solid red;
    .imageDivMob {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
      // border: 1px solid blue;
      img {
        // position: sticky;

        min-height: 350px;

        object-position: center;
        // border: 1px solid red;
        object-fit: cover;
        // height: 100%;
        width: 100%;
        // transform: scale(1.8);
        transform-origin: 50% 50%;
      }
    }
  }
}

@keyframes hide_div {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes show_div {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  text-align: center;
  background-color: vars.$color-dark_grey_50;
  color: white;
  &:hover {
    background-color: vars.$color-white;
    color: vars.$color-black;
  }
}

.imageDivMob {
  height: 400px;

  .card {
    width: 100%;
    // border: 1px solid red;
    // border-radius: 40px;
    object-fit: fill;
    .mobImageCont {
      height: 320px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      // height: 100%;
      img {
        max-width: 100%;
        transform: scale(1.6);
      }
    }
    .mobImageContL {
      // border: 1px solid red;
      // overflow: hidden;
      height: 320px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // height: 100%;
      img {
        max-width: 100%;
        transform: scale(2);
        transform-origin: 25% 75%;
      }
    }
    .mobImageContR {
      // border: 1px solid red;
      // overflow: hidden;
      height: 320px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // height: 100%;
      img {
        max-width: 100%;
        transform: scale(2.2);
        transform-origin: 70% 25%;
      }
    }
  }
}

.buttonMobile {
  border-radius: 30px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-dark_grey_50;
  color: white;
  font-size: 1.6rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  &:hover {
    background-color: vars.$color-white;
    color: vars.$color-black;
  }
}

.space {
  height: 9vh;
  @media screen and (min-width: 2000px) {
    height: 36vh;
  }
}

.cardDiv {
  // height: 450px;
  top: 10vh;
  width: 40%;
  height: 60%;
  overflow: hidden;
  position: sticky;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    transform: scale(1.3);
    transform-origin: 50% 50%;
    @media screen and (max-width: 1500px) {
      transform-origin: 50% 50%;
      transform: scale(1.5);
    }
    @media screen and (max-width: 1100px) {
      transform-origin: 50% 20%;
      transform: scale(1.8);
    }
  }
}

.webcard {
  height: 32vh;
  overflow: hidden;
  // border: 1px solid red;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transform: scale(1.5);
  }
}

.twomobiles {
  max-height: 32vh;
  overflow: hidden;
  // border: 1px solid red;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 1500px) {
      transform-origin: 50% 50%;
      transform: scale(1.3);
    }
    @media screen and (max-width: 1100px) {
      transform-origin: 50% 20%;
      transform: scale(1.15);
    }
  }
}

.bollinoMateriale {
  height: 30px;
  width: 30px;
  // border: 1px solid white;
  border-radius: 50%;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    // transform: scale();
  }
}

.firstcardCont {
  max-height: 950px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // border: 1px solid red;
  overflow: hidden;
  max-width: 576px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    transform: scale(1.3);
    @media screen and (max-width: 1500px) {
      transform-origin: 50% 50%;
      transform: scale(1.5);
    }
    @media screen and (max-width: 1100px) {
      transform-origin: 50% 70%;
      transform: scale(1.55);
    }
  }
}

.secondcardCont {
  max-height: 950px;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // border: 1px solid red;
  overflow: hidden;
  max-width: 576px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.3);
    @media screen and (max-width: 1500px) {
      transform-origin: 50% 50%;
      transform: scale(1.5);
    }
    @media screen and (max-width: 1100px) {
      transform-origin: 50% 70%;
      transform: scale(1.55);
    }
  }
}

.thirdcardCont {
  max-height: 950px;
  padding-top: 20rem;
  // border: 1px solid red;
  overflow: hidden;
  max-width: 576px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.3);
    @media screen and (max-width: 1500px) {
      transform-origin: 50% 50%;
      transform: scale(1.5);
    }
    @media screen and (max-width: 1100px) {
      transform-origin: 50% 70%;
      transform: scale(1.55);
    }
  }
}

.imgBorder {
  border: 2px solid white;
}

.persCardL {
  // overflow: hidden;
  max-width: 100%;
  max-height: 300px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.8);
    transform-origin: 25% 65%;
    z-index: 2;
  }
}
.persCardR {
  // overflow: hidden;
  max-width: 100%;
  max-height: 300px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.8);
    transform-origin: 75% 25%;
    z-index: 2;
  }
}

.webPortalMobContainer {
  min-height: 400px;
  // overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(2);
  }
}

.webPortalMobContainer2 {
  min-height: 550px;
  // overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(2);
  }
}

.mobileMaterialImg {
  height: 40px;
  width: 40px;
  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.leftImg {
  height: 200px;
  // width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.4);
    transform-origin: 5% 65%;
    z-index: 1;
  }
}

.rightImg {
  height: 200px;
  // width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.4);
    transform-origin: 95% 25%;
    z-index: 2;
  }
}

.webPageImg {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.3);
    // transform-origin: 95% 25%;
    // z-index: 2;
  }
}

.personalizationCardDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100px;
  // border: 1px solid yellow;
}

.personalizationPageDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100px;
  // border: 1px solid yellow;
}

.videoContainer {
  height: 28vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  // border: 1px solid green;
  img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
  }
}

.mobileVideoContainer {
  height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // border: 1px solid green;
  img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 80%;
    width: 180%;
  }
}

.materialBtn {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 3px solid white;
  color: white;
  background-color: black;
  font-weight: bold;
  padding: 20px;

  &:hover {
    color: black;
    background-color: white;
  }
}
.materialBtnMob {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 3px solid white;
  color: white;
  background-color: black;
  font-weight: bold;
  padding: 20px;
}
.selectedMaterial {
  color: black !important;
  background-color: white !important;
}
.selectedMaterialMob {
  color: black !important;
  background-color: white !important;
}

.colorBtn {
  margin-left: 20px;
  margin-right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  border: 3px solid white;
  object-fit: scale-down;
  overflow: hidden;
  opacity: 0.5;
  img {
    width: 40px;
    height: 40px;
  }
}
.colorBtnMob {
  margin-left: 20px;
  margin-right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  border: 3px solid white;
  object-fit: scale-down;
  overflow: hidden;
  opacity: 0.5;
  img {
    width: 40px;
    height: 40px;
  }
}

.colorBtnSelected {
  margin-left: 20px;
  margin-right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  border: 3px solid white;
  object-fit: scale-down;
  overflow: hidden;
  opacity: 1;
  img {
    width: 40px;
    height: 40px;
  }
}
.colorBtnMobSelected {
  margin-left: 20px;
  margin-right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  border: 3px solid white;
  object-fit: scale-down;
  overflow: hidden;
  img {
    width: 40px;
    height: 40px;
  }
}

.selectedColor {
  border: 3px solid #e3fc02;
}
.advantagesContainer {
  width: 76%;

  .advantagesCardMaxi {
    min-height: 200px;
    width: 60%;
    border-radius: 20px;
    background-color: white;
    // border: 3px solid red;
    color: black;
    .cardLogo {
      height: 40px;
    }
    .cardText {
      min-height: 180px;
      padding-bottom: 20px;
    }
  }

  .advantagesCardMini {
    min-height: 300px;
    width: 35%;
    border-radius: 20px;
    background-color: white;
    // border: 3px solid white;
    color: black;
    .cardLogo {
      height: 40px;
    }
    .cardText {
      min-height: 150px;
      padding-bottom: 10px;
    }
  }
}

.advantagesCardMiniMob {
  min-height: 200px;
  width: 100%;
  border-radius: 20px;
  background-color: white;
  // border: 3px solid white;
  color: black;
  .cardLogo {
    height: 40px;
  }
  .cardText {
    min-height: 80px;
    padding-bottom: 10px;
  }
}

.advantagesCardMaxiMob {
  color: black;
  min-height: 300px;
  width: 100%;
  border-radius: 20px;
  background-color: white;
  // border: 3px solid white;
  color: black;
  .cardLogo {
    height: 40px;
  }
  .cardText {
    min-height: 220px;
  }
}

.cardDetails {
  .cardDetailsCenter {
    // margin-top: 10px;
    border-left: 1px solid black;
  }
  .cardDetailsRight {
    border-left: 1px solid black;
  }
  .cardDetailsTitle {
    margin-top: 20px;
    height: 40px;
  }
  .cardDetailsDescription {
    width: 100%;
    text-align: center;
    height: 110px;
    // border: 1px solid red;
    padding-inline: 10px;
    padding-top: 20px;
  }
  .cardDetailsImgWood {
    height: 300px;
    width: 100%;
    // border: 1px solid blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .cardDetailsImg {
    height: 300px;
    width: 100%;
    // border: 1px solid blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      height: 75%;

      object-fit: scale-down;
    }
  }
  .cardDetailsParagraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
}

.pricesDiv {
  height: 100px;
  border-top: 1px solid vars.$color-dark_grey_50;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pricesDivEmpty {
  height: 100px;
  // border-top: 1px solid vars.$color-dark_grey_50;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pricesDivPersonalization {
  height: 100px;
  // border: 1px solid red;
  border-top: 1px solid vars.$color-dark_grey_50;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pricesColumn {
  border-left: 1px solid black;
}

.swiperContainer {
  overflow-x: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.efficiencySlide {
  height: 700px;
  cursor: pointer;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 576px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .innovationBackgroundImage {
    height: 100%;
    // width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      // transform-origin: 20% 45%;
      // transform: scale(3.5);
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.moneySlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 576px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .environmentBackgroundImage {
    height: 100%;
    // width: 100%;
    // z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      // transform-origin: 52% 50%;
      // transform: scale(1.6);
      // @media screen and (max-width: 576px) {
      //   transform-origin: 50% 50%;
      //   transform: scale(1.62);
      // }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.environmentSlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 576px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .privacyBackgroundImage {
    height: 100%;

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.timeSlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 576px) {
    height: 500px;
  }
  @media screen and (max-width: 1200px) {
    height: 600px;
  }
  .customizationBackgroundImage {
    height: 100%;
    // width: 100%;
    // z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      // transform-origin: 35% 45%;
      // transform: scale(1.6);
      // @media screen and (max-width: 576px) {
      //   transform-origin: 35% 50%;
      //   transform: scale(1.6);
      // }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.buttonRow {
  z-index: 2;

  bottom: 0;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  height: 60px;
  .button {
    transition: transform 0.35s;
    background-color: vars.$color-dark_grey_50;
    height: 30px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 20px;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.animationContainer {
  height: 70vh;
  width: 45vw;
  overflow: hidden;
  // border: 1px solid red;
  video {
    width: 100%;
    // height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 576px) {
    height: 65vh;
    width: 90vw;
    video {
      // height: 50vh;
      object-fit: cover;
    }
  }
}

.inputDiv {
  border-radius: 70px;
  height: 55px;
  border: 3.5px solid vars.$color-dark_grey_50;
  // width: 100%;
  // margin-inline-end: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 25px;
  @media screen and (max-width: 576px) {
    margin-inline-end: 1.5vh;
  }
  input[type="text"] {
    background-color: white;
    color: black;

    border: 0px;
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
      // border: 1px solid red;
      @media screen and (max-width: 576px) {
        font-size: 1rem;
      }
    }
  }
}

.textareaDiv {
  //   border: 3.5px solid vars.$color-dark_grey_50;
  width: 100%;
  margin-inline-end: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;

  textarea {
    border: 3.5px solid vars.$color-dark_grey_50;
    box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 25px;
    border-radius: 28px;
    height: 200px;
    color: black;
    // background-color: transparent;
    resize: none;
    &:focus {
      color: black;
      outline: none;
    }
    &::placeholder {
      color: vars.$color-dark_grey_50;
      font-size: 1.4rem;
      @media screen and (max-width: 576px) {
        font-size: 1rem;
      }
    }
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // border:1px solid green;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  // border:1px solid red;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 10px;
  top: 2px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sendButton {
  height: 55px;
  border-radius: 30px;
  width: 100%;
  border: 0px;
  background-color: black;
  box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  &:hover {
    background-color: vars.$color-dark_grey_50;
  }
}

.webInterfaceImgContainer {
  height: 520px;
  overflow: hidden;

  // border: 1px solid red;
  // overflow: hidden;
  @media screen and (max-width: 576px) {
    height: 350px;
    // border: 1px solid red;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    // object-position: center;
  }
}

.addButtonGreen {
  background-color: #b2fc02;
  // border: 3px solid white;
  color: black;
  height: 48px;
  border-radius: 20px;
}

.exchangeButtonWhite {
  background-color: white;
  // border: 3px solid white;
  color: black;
  height: 48px;
  border-radius: 20px;
  border: 1px solid black;
  .btniconContainer {
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.blackDiv {
  color: white;
  background-color: black;
  border-radius: 20px;
  height: 350px;
  // border: 3px solid black;
}

.platforTypeBtn {
  height: 40px;
  border: 2px solid black;
  color: black;
  border-radius: 20px;
}
.activePlatformTypeBtn {
  background-color: black;
  color: white;
}
.webPlatImageContainer {
  img {
    width: 100%;
    object-fit: contain;
    height: 700px;
    @media screen and (max-width: 600px) {
      width: 100%;
      // height: 300px;
      object-fit: contain;
      height: 250px;
    }
  }
}
.planContainer {
  height: 650px;
  // border: 1px solid red;
  @media screen and (max-width: 765px) {
    height: auto;
  }
  .planDiv {
    height: 650px;
    border-radius: 20px;
    border: 5px solid black;
    position: relative;
    @media screen and (max-width: 600px) {
      // transform-origin: 50% 80%;
      height: 550px !important;
      width: 100%;
    }
    .planDesc {
      min-height: 90px;
      // border: 1px solid red;
    }

    .planBtn {
      position: absolute;
      bottom: 30px;
      height: 40px;
      border-radius: 20px;
      background-color: #b2fc02;
      cursor: pointer;
      &:hover {
        background-color: vars.$color-light-gray-5;
      }
    }
  }

  .mobileTendina {
    height: 50px;
    border: 5px solid black;
    border-radius: 20px;
  }
}
.yearlyDivNo {
  height: 40px;
}
.yearlyDiv {
  height: 40px;
  border-radius: 20px;
  background-color: vars.$color-light-gray-2;
  min-width: 130px;
  .toggleContainer {
    width: 50px; /* Larghezza del toggle */
    height: 30px; /* Altezza del toggle */
    background-color: #ccc; /* Colore di sfondo disattivato */
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 3px; /* Spaziatura interna */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out; /* Animazione del colore */
    position: relative;

    &.active {
      background-color: #007bff; /* Colore di sfondo attivato */

      .toggleBall {
        transform: translateX(20px); /* Sposta la pallina a destra */
      }
    }

    .toggleBall {
      width: 24px; /* Dimensioni della pallina */
      height: 24px;
      background-color: white; /* Colore della pallina */
      border-radius: 50%;
      transition: transform 0.3s ease-in-out; /* Animazione dello spostamento */
      position: absolute;
      top: 3px; /* Posizione dall'alto */
      left: 3px; /* Posizione da sinistra */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ombra per la pallina */
    }
  }
}

.videoContainer {
  height: 80vh;
//   border: 1px solid red;
  width: 100%;

  video{
    height: 100%;
    width: 90%;
    object-fit: cover;
    border-radius: 20px;
  }
}
.buynow_btn{
    height: 40px;
    color: black;
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
}

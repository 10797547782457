@use "../../scss/variables.scss" as vars;

.rightDiv {
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 80%;
  &.fixed {
    top: 16vh;
    position: fixed;
    width: 39%;
    height: 80%;
  }
  &.hidden {
    top: 16vh;
    position: fixed;
    width: 39%;
    z-index: 0;
    animation: hide_div 0.35s;
    opacity: 0;
  }
}

@keyframes hide_div {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes show_div {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  min-height: 50px;
  background-color: vars.$color-dark_grey_50;
  color: white;
  &:hover {
    background-color: vars.$color-white;
    color: vars.$color-black;
  }
}

.video {
  object-fit: contain;

  img {
    border-radius: 28px;
    border: 2px solid white;
    max-width: 83.5%;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }
}

.imageDivMob {
  height: 400px;
  // border: 1px solid red;
  .card {
    width: 100%;
    height: 100%;
    // border: 1px solid black;

    .mobImageCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      // border: 1px solid red;

      overflow: hidden;
      height: 100%;
      img {
        object-fit: fill;
        max-width: 100%;
        transform: scale(1.7);
        transform-origin: 50% 50%;
        // border: 1px solid red;
      }
    }
  }
}

.buttonMobile {
  border-radius: 30px;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-dark_grey_50;
  color: white;
  font-size: 1.6rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  &:hover {
    background-color: vars.$color-white;
    color: vars.$color-black;
  }
}

.space {
  height: 15vh;
  @media screen and (min-width: 2000px) {
    height: 36vh;
  }
}

.imageContainer {
  top: 10vh;
  // border: 1px solid red;
  width: 40%;
  height: 60%;
  position: sticky;
  // top: 0px;

  overflow: hidden;

  .image {
    // background-color: white;
    border-radius: 30px;
    object-position: center;

    .imageDiv {
      overflow: hidden;
      // border: 1px solid red;

      img {
        // position: sticky;
        min-height: 450px;
        height: 100%;

        object-position: center;
        // border: 1px solid red;
        object-fit: contain;
        height: 100%;
        width: 100%;
        transform: scale(1.5);
        transform-origin: 50% 60%;
      }
    }
  }
}

.technologyDiv {
  .technologyDivImg {
    height: 300px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .technologyDivText {
    border: 1px solid white;
    border-radius: 20px;
    min-height: 180px;
  }
}
.swiperContainer {
  overflow-x: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.dimensionsSlide {
  height: 700px;
  cursor: pointer;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  .innovationBackgroundImage {
    height: 100%;
    // width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      // transform-origin: 20% 45%;
      // transform: scale(3.5);
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.technologySlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  .environmentBackgroundImage {
    height: 100%;
    // width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      // transform-origin: 52% 50%;
      // transform: scale(1.6);
      @media screen and (max-width: 800px) {
        // transform-origin: 50% 50%;
        // transform: scale(1.62);
      }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.designSlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  .privacyBackgroundImage {
    height: 100%;
    // width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      // transform-origin: 35% 40%;
      // transform: scale(1.6);
      @media screen and (max-width: 800px) {
        // transform-origin: 35% 50%;
      }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.customizationSlide {
  cursor: pointer;
  height: 700px;
  transition: transform 0.75s;
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  .customizationBackgroundImage {
    height: 100%;
    // width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      // transform-origin: 35% 45%;
      // transform: scale(1.6);
      @media screen and (max-width: 800px) {
        // transform-origin: 35% 50%;
        // transform: scale(1.6);
      }
      // border: 1px solid red;
    }
  }
  .title {
    z-index: 2;
    top: 0px;
    position: absolute;
  }
  .text {
    z-index: 2;
    top: 30px;
    position: absolute;
    line-height: 1.1;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.animationContainer {
  height: 70vh;
  width: 45vw;
  overflow: hidden;
  // border: 1px solid red;
  video {
    width: 100%;
    // height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 800px) {
    height: 65vh;
    width: 90vw;
    video {
      // height: 50vh;
      object-fit: cover;
    }
  }
}
.buttonRow {
  z-index: 2;

  bottom: 0;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  height: 60px;
  .button {
    transition: transform 0.35s;
    background-color: vars.$color-dark_grey_50;
    height: 30px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 20px;
    &:hover {
      transform: scale(1.2);
    }
  }
}

@use "../../scss/variables.scss" as vars;

.rightDiv {
  border-radius: 40px;
  display: flex;
  width: 100%;
  height: 80vh;
  flex-direction: column;
  align-items: center;

  &.fixed {
    top: 16vh;
    position: fixed;
    width: 39%;
    z-index: 0;
  }

  &.hidden {
    top: 16vh;
    position: fixed;
    width: 39%;
    z-index: 0;
    animation: hide_div 0.35s;
    opacity: 0;
  }
}

.imageContainer {
  top: 5.5vh;
  // border: 1px solid red;
  width: 40%;
  height: 60%;
  position: sticky;
  // top: 0px;
  border-radius: 30px;
  overflow: hidden;

  img {
    // position: sticky;
    min-height: 450px;

    object-position: center;
    // border: 1px solid red;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}

@keyframes hide_div {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes show_div {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  background-color: vars.$color-dark_grey_50;
  color: white;
  min-width: 200px;
  &:hover {
    background-color: vars.$color-white;
    color: vars.$color-black;
  }
}

.planDiv {
  min-height: 65vh;
  //   border: 1px solid white;
  border-radius: 50px;
}

.textDiv {
  .image {
    max-height: 50px;
    max-width: 50px;
  }
}

.pagediv {
  padding: 5rem;
  @media screen and (max-width: 700px) {
    padding: 3rem;
  }
}

.space {
  height: 11vh;
  @media screen and (min-width: 2000px) {
    height: 36vh;
  }
}
